<template>
    <div class="row">
        <div class="col-12">
            <div class="detailTitle">
                <h1>{{ property.title }} <span class="icon-crown"></span></h1>
                <ul>
                    <li>{{ property.address }}</li>
                    <li>
                        <span class="brand-color">For {{ property.purpose }}</span>
                    </li>
                </ul>
            </div>
        </div>
        <div class="col-xl-8 mt-3">
            <div class="propertyDetail-content">
                <div class="display-card">
                    <ImageSlider :images="property_images" />
                    <div class="property-content p-3">
                        <div class="row">
                            <div class="col-xl-9">
                                <p>Property ID: {{ property.property_code }}</p>
                                <div v-if="property.purpose == 'rent'" class="pricing">
                                    <span>{{ property.currency }}: {{ property.price }}/{{ property.price_for }}</span>
                                </div>
                                <div v-else class="pricing">
                                    <span>{{ property.currency }}: {{ property.price }}</span>
                                </div>

                                <span class="categoryTag">
                                    <router-link :to="'/search?category=' + property_category.slug">{{
                                        property_category.title }}</router-link>
                                </span>
                            </div>
                            <!-- <div class="col-xl-3 property-mob">
                                <router-link to="" class="univ-btn w-100 text-center mb-2">Request a tour</router-link>
                                <router-link to="" class="w-100 text-center mb-2 univ-btn-line"><i class="fas fa-phone-alt"></i> Call Agent</router-link>
                            </div> -->
                        </div>
                        <section id="headerSection" class="section details-header--section">
                            <div class="details-header">
                                <ul class="details-header-nav">
                                    <li><a href="#sectionOverview" style="">Overview</a></li>
                                    <li><a href="#specificationView" style="">Specifications</a></li>
                                    <li><a href="#sectionAmenities">Amenities</a></li>
                                    <li><a href="#sectionViewMap">Maps</a></li>
                                </ul>
                            </div>
                        </section>
                        <div id="sectionOverview">
                            <h3>Overview</h3>
                            <div class="description">{{ property.description }}</div>
                        </div>
                        <div id="specificationView">
                            <h3>Specifications</h3>
                            <div class="specifications">
                                <ul class="d-flex properties-icons-fix">
                                    <li class="d-flex icons-fix-wrapper" v-if="property.livingroom"> Living Room: {{ property.livingroom }}</li>
                                    <li class="d-flex icons-fix-wrapper" v-if="property.floor"> Floor: {{ property.floor }}</li>
                                    <li class="d-flex icons-fix-wrapper" v-if="property.bathroom"> Bathroom: {{ property.bathroom }}</li>
                                    <li class="d-flex icons-fix-wrapper" v-if="property.bedroom"> Bedroom: {{ property.bedroom }}</li>
                                    <li class="d-flex icons-fix-wrapper" v-if="property.road_type"> Road Type: {{ property.road_type }}</li>
                                    <li class="d-flex icons-fix-wrapper" v-if="property.kitchen"> Kitchen: {{ property.kitchen }}</li>
                                    <li class="d-flex icons-fix-wrapper" v-if="property.land_mark"> Land Mark: {{ property.land_mark }}</li>
                                    <li class="d-flex icons-fix-wrapper" v-if="property.land_size"> Land Size (Aana/Dhur): {{ property.land_size }}</li>
                                    <li class="d-flex icons-fix-wrapper" v-if="property.buildup"> Buildup (Sqr. Ft.): {{ property.buildup }}</li>
                                    <li class="d-flex icons-fix-wrapper" v-if="property.furnishing"> Furnishing: {{ property.furnishing }}</li>
                                    <li class="d-flex icons-fix-wrapper" v-if="property.road_size"> Road Size: {{ property.road_size }}</li>
                                </ul>
                            </div>
                        </div>
                        <div id="sectionAmenities">
                            <h3>Amenities</h3>
                            <div v-if="property_features.length > 0" class="extra-list mt-3 mb-3">
                                <ul class="d-flex properties-icons-fix">
                                    <li class="d-flex icons-fix-wrapper" v-for="(feature,f) in property_features" :key="f">
                                        <div class="icon-properties-wrapper"><img :src="feature.icon_path"></div> {{ feature.title }}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div id="sectionViewMap">
                            <h3>Google Map</h3>
                            <div class="google-map">
                            
                            </div>
                        </div>
                        <div class="sellerDetail">
                            <h3>Seller Details</h3>
                            <p><strong><i class="fa fa-user-circle-o" aria-hidden="true"></i> {{ sellerInfo.name }}</strong></p>
                            <p><strong><i class="fa fa-envelope-o" aria-hidden="true"></i></strong> {{ sellerInfo.email }}</p>
                            <p v-if="sellerInfo.address"><strong><i class="fa fa-map-marker" aria-hidden="true"></i></strong> {{ sellerInfo.address }}</p>
                        </div>
                        <hr />
                        <h3>Contact Agent</h3>
                        <ContactForm :requestData="contactData" :textMsg="textMsg" />
                    </div>
                </div>
            </div>
        </div>
        <SimilarList :properties="similar_properties" />
    </div>
</template>
<script>
import axios from "axios";
import api from "@/services/api";
import SimilarList from "@/views/PropertyDetail/partials/SimilarList.vue";
import ImageSlider from "@/components/ImgSlider";
import ContactForm from "../../../components/ContactFrom.vue";
import store from "@/store";
import Helper from "../../../services/helper";
export default {
    name: "DetailContent",
    components: { SimilarList, ImageSlider, ContactForm },
    data() {
        return {
            page_slug: this.$route.params.slug,
            property: [],
            property_images: [],
            property_category: [],
            property_features: [],
            similar_properties: [],
            sellerInfo:"",
            contactData: {
                name: store.getters.user.name,
                email: store.getters.user.email,
                phone_number: store.getters.user.mobile_number,
                message: "",
                source: "AGENT_CONTACT",
            },
            textMsg: "",
        };
    },
    mounted() {
        let app = this;
        app.renderDetail();
    },
    methods: {
        async renderDetail() {
            let app = this;
            Helper.showSpinner();
            await axios.get(api.getUrl("/properties/" + app.page_slug)).then(function (response) {
                if (response.data.success) {
                    document.title = response.data.data.title;
                    app.page_title = response.data.data.title;
                    app.page_description = response.data.data.description;
                    app.property = response.data.data;

                    app.property_images = app.property.property_images;
                    app.property_category = app.property.category;
                    app.property_features = app.property.property_features;
                    app.similar_properties = app.property.similar_properties;
                    app.sellerInfo = app.property.client;

                    app.textMsg = "I am interested in this property [ID: " + app.property.property_code + "] ";
                }
            }).finally(() => {
                Helper.hideSpinner();
            });
        },
    },
    created() {
        this.$watch(() => this.$route.params, (newParam) => {
            this.page_slug = newParam.slug;
            this.renderDetail();
        })
    },
};
</script>
  